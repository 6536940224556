
<template>
<v-container>

    <transition >
                 <div v-if = "this.get_Random_Nbr() == 1">
                                                            <p class="animate__animated animate__fadeInLeftBig">  🌟            </p>
                                                            <p class="animate__animated animate__backInLeft">     ❤️ + 💙 = 💣 </p>
                 </div>

                 <div v-else-if = "this.get_Random_Nbr() == 2">
                                                            <p class="animate__animated animate__fadeInRightBig">  🌟🌟          </p>
                                                            <p class="animate__animated animate__backInRight">     ❤️ + 💙 =  🧨</p>
                 </div>

                 <div v-else-if = "this.get_Random_Nbr() == 3">
                                                            <p class="animate__animated animate__fadeInTopLeft">  🌟🌟🌟       </p>
                                                            <p class="animate__animated animate__backInUp">       ❤️ + 💙 = 🥠 </p>
                 </div>

                 <div v-else-if = "this.get_Random_Nbr() == 4">
                                                            <p class="animate__animated animate__fadeInTopRight">  🌟🌟🌟🌟     </p>
                                                            <p class="animate__animated animate__backInUp">        ❤️ + 💙 = ✨ </p>
                 </div>

                 <div v-else = "this.get_Random_Nbr() == 5">
                                                            <p class="animate__animated animate__fadeInDown">     🌟🌟🌟🌟🌟   </p>
                                                            <p class="animate__animated animate__bounceInUp">     ❤️ + 💙 = 🧧  </p>
                 </div>

                </transition>                
                
                 <transition>
                 <p v-if      = "this.get_Random_Nbr() == 1" class="animate__animated animate__backInLeft">   ❤️:♈♉♊♋♌♍♎♏♐♑♒♓ </p>
                 <p v-else-if = "this.get_Random_Nbr() == 2" class="animate__animated animate__backInRight">  ❤️:♈♉♊♋♌♍♎♏♐♑♒♓ </p>
                 <p v-else-if = "this.get_Random_Nbr() == 3" class="animate__animated animate__backInUp">     ❤️:♈♉♊♋♌♍♎♏♐♑♒♓ </p>
                 <p v-else-if = "this.get_Random_Nbr() == 4" class="animate__animated animate__backInUp">     ❤️:♈♉♊♋♌♍♎♏♐♑♒♓ </p>
                 <p v-else    = "this.get_Random_Nbr() == 5" class="animate__animated animate__bounceInUp">   ❤️:♈♉♊♋♌♍♎♏♐♑♒♓ </p>
                 </transition>

                 <transition>   
                 <p v-if      = "this.get_Random_Nbr() == 5" class="animate__animated animate__backInLeft">   ☯️:🐀🐮🐯🐰🐲🐍🐴🐐🐵🐔🐶🐷 </p>
                 <p v-else-if = "this.get_Random_Nbr() == 4" class="animate__animated animate__backInRight">  ☯️:🐀🐮🐯🐰🐲🐍🐴🐐🐵🐔🐶🐷</p>
                 <p v-else-if = "this.get_Random_Nbr() == 3" class="animate__animated animate__backInUp">     ☯️:🐀🐮🐯🐰🐲🐍🐴🐐🐵🐔🐶🐷 </p>
                 <p v-else-if = "this.get_Random_Nbr() == 2" class="animate__animated animate__backInUp">     ☯️:🐀🐮🐯🐰🐲🐍🐴🐐🐵🐔🐶🐷 </p>
                 <p v-else    = "this.get_Random_Nbr() == 1" class="animate__animated animate__bounceInUp">   ☯️:🐀🐮🐯🐰🐲🐍🐴🐐🐵🐔🐶🐷 </p>
                 </transition>
    </transition>



     <v-row>
     <v-col>

                <v-card max-width="360" elevation="2" outlined shaped>
                    <v-card-title> what is _ astroSimpatico ? </v-card-title> 
                        <v-card-text>
                                        <p> Personality characteristics are related to 
                                            year, month, & date of birth, as the Earth
                                            transverses within a band of stars (the Zodiac)   
                                            and planets.  </p>
                                            
                                        <p> At birth _ the vibrational energies from
                                            celestial bodies are unique for each
                                            individual _ some unmistakable characteristics
                                            appear.  </p>

                                        <p> Comparing 2 birth dates _ </p>

                                        <p> When 2 SIGNs simpatico, more ❤️ _ <i> (5 max) </i></p>
                                        
                                        <p> When 2 YEARs align _ Chinese <i> (c☯️mplex) </i></p>
                        </v-card-text>
                </v-card><v-spacer></v-spacer> <br>

       </v-col> 
       </v-row>


</v-container>
</template>



<!-------------------------------------------------------------------------------->
<script>

 import { get_Random } from '../mixins/get_Random' ;

  export default {
                    data() {  return {
                                        isOpen: false,
                                        taxiCalled: false
                                     }
                          },

                    mixins: [get_Random]
  }


</script>
